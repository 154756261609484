import React from 'react';

import {
  routeLetters,
  date,
  months,
} from 'config/constants/routes';

import { fetchPageData } from 'common/redux/pages';

import { IAppRoute } from 'typings/AppRoute';

import DefaultPage from 'desktop/pages/Default';

export const primetyRoutes: IAppRoute[] = [
  {
    name:      'primety',
    path:      '/primety',
    exact:     true,
    render:    props => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }) => {
      await dispatch(fetchPageData('omens', 'main', undefined, false, {
        top100: 'primety_main',
      }));
    },
  },
  {
    name:      'primety-word',
    path:      '/primety/word/:word',
    exact:     true,
    render:    props => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }, { params }) => {
      const { word } = params;
      await dispatch(fetchPageData('omens', 'word', {
        url:       word,
        omensWord: word,
      }, false, {
        top100: 'primety_tag_page',
      }));
    },
  },
  {
    name:      'primety-letter',
    path:      `/primety/letter/:letter(${routeLetters})`,
    exact:     true,
    render:    props => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }, { params }) => {
      const { letter } = params;
      await dispatch(fetchPageData('omens', 'letter', {
        url:         `letter/${letter}`,
        omensLetter: letter,
      }, false, {
        top100: 'primety_letter_page',
      }));
    },
  },
  {
    name:      'primety-calendar-main',
    path:      '/primety/calendar',
    exact:     true,
    render:    props => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }) => {
      await dispatch(fetchPageData('omens', 'calendar', undefined, false, {
        top100: 'primety_calendar_main',
      }));
    },
  },
  {
    name:      'primety-calendar-month',
    path:      `/primety/calendar/:month(${months})`,
    exact:     true,
    render:    props => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }, { params }) => {
      const { month } = params;

      await dispatch(fetchPageData('omens', 'month', {
        url: month,
      }, false, {
        top100: 'primety_period_month',
      }));
    },
  },
  {
    name:      'primety-calendar-date',
    path:      `/primety/calendar/:year(${date.year})-:month(${date.month})-:day(${date.day})`,
    exact:     true,
    render:    props => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }, { params }) => {
      const {
        year,
        month,
        day,
      } = params;

      await dispatch(fetchPageData('omens', 'date', {
        url:      `${year}-${month}-${day}`,
        moonDate: `${year}-${month}-${day}`,
      }, false, {
        top100: 'primety_period_date',
      }));
    },
  },
];
