// @ts-nocheck
import { YANDEX_METRICS } from 'config/constants/counters';

/**
 * https://yandex.ru/support/metrika/code/counter-initialize.html#counter-initialize__counter_params
 * https://yandex.ru/support/metrika/code/counter-spa-setup.html
 * Обработка двух кейсов при отправке ивентов в метрику
 * – ивент отправили, но счетчик еще не прогрузился
 * – счетчик уже прогрузился - можно отправлять сразу
 */
class YandexEvent {
  id: YANDEX_METRICS;

  constructor(id: number) {
    this.id = id;

    document.addEventListener(`yacounter${this.id}inited`, this.onCounterInit);
  }

  eventsPool: any[] = [];

  onCounterInit = () => {
    this.eventsPool.forEach(event => this.send(event));
    this.eventsPool = [];
  };

  send(event: any, callback?: any) {
    if ((window[`yaCounter${this.id}`] && window.ym) || __TEST__) {
      if (event.type === 'params') {
        window.ym(this.id, event.type, event.params || {});
        return;
      }
      window.ym(this.id, event.type, event.data, event.params || {});
      document.removeEventListener(`yacounter${this.id}inited`, this.onCounterInit);
    } else {
      this.eventsPool.push(event);
    }
    if (callback) {
      callback();
    }
  }
}

export default YandexEvent;
