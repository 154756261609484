import React from 'react';
import loadable from '@loadable/component';

import { fetchPageData } from 'common/redux/pages';

import { IAppRoute } from 'typings/AppRoute';

import DefaultPage from 'desktop/pages/Default';

const GamePage = loadable(() => import('desktop/pages/Game'));

export const fortuneRoutes: IAppRoute[] = [
  {
    name:      'fortune-main',
    path:      '/gadanie-online',
    exact:     true,
    component: DefaultPage,
    fetchData: async ({ dispatch }) => {
      await dispatch(fetchPageData('fortune', 'main', undefined, false, {
        top100: 'fortune::main',
      }));
    },
  },
  {
    name:      'fortune-coffee',
    path:      '/gadanie-online/kofeynaya-gushcha',
    exact:     true,
    render:    props => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }) => {
      await dispatch(fetchPageData('fortune', 'coffee', undefined, false, {
        top100: 'fortune::coffee',
      }));
    },
  },
  {
    name:      'fortune-tarot-celtic',
    path:      '/gadanie-online/taro-keltskiy-krest',
    exact:     true,
    component: GamePage,
    fetchData: async ({ dispatch }) => {
      await dispatch(fetchPageData('fortune', 'tarot', {
        url: '',
      }, false, {
        top100:         'fortune::tarot::celtic',
        isBubblesMoved: true,
      }));
    },
  },
  {
    name:      'fortune-tarot-celtic-with-params',
    path:      '/gadanie-online/taro-keltskiy-krest/:key/card-:card',
    exact:     true,
    component: GamePage,
    fetchData: async ({ dispatch }, { params }) => {
      const { key, card } = params;
      await dispatch(fetchPageData('fortune', 'tarot', {
        url:            `${key}/card-${card}`,
        specialKey:     key,
        specialCurrent: card,
      }, false, {
        top100:         'fortune::tarot::celtic',
        isBubblesMoved: true,
      }));
    },
  },
  {
    name:      'fortune-runes',
    path:      '/gadanie-online/gadanie-na-runah',
    exact:     true,
    component: GamePage,
    fetchData: async ({ dispatch }) => {
      await dispatch(fetchPageData('fortune', 'runes', {
        url: '',
      }, false, {
        top100: 'fortune::runes',
      }));
    },
  },
  {
    name:      'fortune-runes-with-params',
    path:      '/gadanie-online/gadanie-na-runah/:key/rune-:rune',
    exact:     true,
    component: GamePage,
    fetchData: async ({ dispatch }, { params }) => {
      const { key, rune } = params;
      await dispatch(fetchPageData('fortune', 'runes', {
        url:            `${key}/rune-${rune}`,
        specialKey:     key,
        specialCurrent: rune,
      }, false, {
        top100: 'fortune::runes',
      }));
    },
  },
];
