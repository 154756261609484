import React from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';

import { Ad } from 'common/components/Ad';
import { Menu } from 'desktop/components/Menu/';
import { ToplineDesktop } from 'desktop/components/Topline';
import { ToplineMobile } from 'common/components/ToplineMobile';

import { selectPageData } from 'utils/selectPageData';

import { AD_BACKGROUNDS_BY_THEMES } from 'config/constants/ad';
import { STRICT_CONTENT_PAGES } from 'config/constants/routes';

import s from './styles.css';

const selectData = createSelector(
  [
    (state: IAppState) => state.runtime.top100,
    (state: IAppState) => state.runtime.currentPage,
    (state: IAppState) => state.runtime.currentSection,
    (state: IAppState) => state.runtime.isPremium,
    (state: IAppState) => state.runtime.isBranding,
    (state: IAppState) => state.account.theme,
    (state: IAppState) => state.runtime.adminData.settings.themes_disabled,
  ],
  (
    top100,
    currentPage,
    currentSection,
    isPremium,
    isBranding,
    theme,
    isThemesDisabled,
  ) => ({
    top100,
    currentPage,
    currentSection,
    isPremium,
    isBranding,
    theme,
    isThemesDisabled,
  }),
);

function TopPart() {
  const {
    top100,
    currentPage,
    currentSection,
    isPremium,
    isBranding,
    theme,
    isThemesDisabled,
  } = useSelector(selectData);

  const {
    content,
  } = useSelector((state: IAppState) => selectPageData(state, currentPage, currentSection));

  const top100Prefix = `${top100}_${content ? 'content_page' : 'list_page'}`;

  if (STRICT_CONTENT_PAGES.includes(currentPage)) {
    return (
      <>
        <ToplineDesktop top100Prefix={top100Prefix} withoutBorder />
        <Menu top100Prefix={top100Prefix} withSubMenu={false} className={s.menu} />
      </>
    );
  }

  const billboard = (
    <div
      className={cn(isBranding && s.billboardWrapperBranding, 'billboard-wrapper')}
      style={isThemesDisabled || isBranding
        ? {}
        : { backgroundColor: AD_BACKGROUNDS_BY_THEMES[theme] }}
    >
      <Ad
        name="billboard"
        wrapperClassName={s.billboard}
        puid44="context_item1"
        isFirstBillboardOrTopBanner
      />
    </div>
  );

  if (isPremium) {
    return (
      <>
        <ToplineMobile isInDesktop />
        {billboard}
      </>
    );
  }

  return (
    <>
      <ToplineDesktop top100Prefix={top100Prefix} />
      {billboard}
      <Ad name="stickyLine" />
      <Ad name="showUp" />
    </>
  );
}

export { TopPart };
