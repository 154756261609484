import isEmpty from 'lodash.isempty';

import fetch from 'common/utils/fetch';
import { sentryReactSend } from 'utils/sentry/client';
import { API } from 'config/constants/api';

const urlPart = {
  dreams: 'dreams/word',
  names:  'names/name',
  omens:  'primety/word',
  taro:   '',
};

export class HDError extends Error {
  type?: 'unavailable' | 'empty';
}

export const fetchSuggestedData = async (
  state: any,
  query: string,
  type: keyof APIsUrls['search'],
) => {
  try {
    const url = `${API.search[type]}?query=${query}&limit=10`;
    const data = await fetch(url, 'GET', state, undefined, undefined, undefined, 'v3');

    const response: any = await new Promise(resolve => {
      resolve(data.slice(0, 10));
    });

    if (isEmpty(response)) {
      const err = new Error(`${new Date()} Не удалось получить данные саджеста: ${url}`);
      throw err;
    }

    const suggests = response.map((item: any) => ({
      type:  '',
      ind:   item.id,
      url:   `/${urlPart[type]}/${item.slug}/`,
      descr: item.name,
      sug:   item.name,
    }));

    return {
      query,
      suggests,
    };
  } catch (error) {
    sentryReactSend(error);
    // eslint-disable-next-line no-console
    console.error(`${new Date()} ${error}`);

    return {
      query,
      suggests: [],
    };
  }
};

export const fetchHumanDesignCitiesData = async (
  state: any,
  query: string,
  country: string,
) => {
  try {
    const url = `${API.humandesign.places}autocomplete?language=ru&input=${encodeURIComponent(query)}&country=${country}`;
    const data = await fetch(url, 'GET', state, undefined, undefined, undefined, 'geo');

    if (!data || !data.search) {
      const err = new HDError(`${new Date()} Данные саджеста городов ДЧ недоступны: ${url}`);
      err.type = 'unavailable';
      throw err;
    }

    const response: any = await new Promise(resolve => {
      resolve(data.cities);
    });

    if (isEmpty(response)) {
      const err = new HDError(`${new Date()} Данные саджеста городов ДЧ пуст: ${url}`);
      err.type = 'empty';
      throw err;
    }

    const suggests = response.map((item: any) => {
      const descr = item.description.replace(/[`~!@#$%^&*_|+=?;:'".<>{}[\]\\/]/gi, '');

      return ({
        type:     '',
        ind:      item.place_id,
        url:      '',
        descr,
        sug:      descr,
        timezone: item.timezone.replace(/[`~!@#$%^&*|=?;:'".<>{}[\]\\]/gi, ''),
      });
    });

    return {
      query,
      suggests,
    };
  } catch (error) {
    sentryReactSend(error);
    // eslint-disable-next-line no-console
    console.error(`${new Date()} ${error}`);

    throw error;
  }
};
