import React from 'react';

import Layout from 'desktop/components/Layout';

type DefaultPageType = {
  withSchema?: boolean
  withoutMainCard?: boolean
  withoutBubbles?: boolean
  withoutPersonal?: boolean
  withTarotMenu?: boolean
  isHomePage?: boolean
  centerColumn?: JSX.Element
};

function DefaultPage({
  withSchema,
  withoutMainCard,
  withoutBubbles,
  withoutPersonal,
  withTarotMenu,
  isHomePage,
  centerColumn,
}: DefaultPageType) {
  return (
    <Layout
      withSchema={withSchema}
      withoutMainCard={withoutMainCard}
      withoutPersonal={withoutPersonal}
      withoutBubbles={withoutBubbles}
      withTarotMenu={withTarotMenu}
      isHomePage={isHomePage}
      centerColumn={centerColumn}
    />
  );
}

DefaultPage.defaultProps = {
  withSchema:      false,
  withoutMainCard: false,
  withoutPersonal: false,
  withoutBubbles:  false,
  withTarotMenu:   false,
  isHomePage:      false,
  centerColumn:    undefined,
};

export default DefaultPage;
