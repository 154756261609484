import React from 'react';

import { fetchPageData } from 'common/redux/pages';

import { IAppRoute } from 'typings/AppRoute';

import DefaultPage from 'desktop/pages/Default';

export const editorsRoutes: IAppRoute[] = [
  {
    name:      'editors-main',
    path:      '/editors',
    exact:     true,
    render:    props => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }) => {
      await dispatch(fetchPageData('editors', 'main', undefined, false, {
        top100: 'editors_main',
      }));
    },
  },
  {
    name:      'editors-author',
    path:      '/editors/:author',
    exact:     true,
    render:    props => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }, { params: { author } }) => {
      await dispatch(fetchPageData('editors', 'author', {
        url:           author,
        editorsAuthor: author,
      }, false, {
        top100: 'editors_author',
      }));
    },
  },
];
