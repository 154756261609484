import { sentryReactSend } from 'utils/sentry/client';

export default (ms: number, promise: any, url: string) => {
  const timeout = new Promise((resolve, reject) => {
    const id = setTimeout(() => {
      clearTimeout(id);

      if (__SERVER__) {
        reject(new Error(`Fetch timed out in ${ms} ms. FetchUrl: ${url}`));
      } else {
        sentryReactSend(new Error('Fetch timed out'), {
          fetchUrl:       url,
          fetchTimeLimit: `${ms}`,
        });
        reject();
      }
    }, ms);
  });

  return Promise.race([promise, timeout]);
};
