import isEmpty from 'lodash.isempty';

import { PUB_SCOPE } from 'config/constants/ad';

type top100OptionsType = {
  pub_id?: string | null,
  pub_scope?: string,
  rambler_id?: string | null,
  user_id?: string | null,
  attributes_dataset: string[],
  splits: string[],
};

type top100InitOptionsType = { projectIds: number[] } & top100OptionsType;

const top100Wrapper = {
  // Флаг, что топста загружена
  isLoaded: false,

  // Очередь функций для загруженной топсты
  pushArray: [] as (() => void)[],

  // IDs проектов топсты, для которых инициирован счетчик
  projectIds: [] as number[],

  // Общие настройки для всех счетчиков
  options: {
    attributes_dataset: [],
    splits:             [],
  } as top100OptionsType,

  // Инициированные счетчики
  top100Counters: {} as {[key: number | string]: any},

  init({ projectIds = [], ...options } = {} as top100InitOptionsType) {
    if (typeof window === 'undefined') {
      return;
    }

    // eslint-disable-next-line
    window._top100q = window._top100q || [];

    this.options = {
      ...options,
      attributes_dataset: [
        ...this.options.attributes_dataset,
        ...options.attributes_dataset,
      ],
      splits: [
        ...this.options.splits,
        ...options.splits,
      ],
      pub_scope: PUB_SCOPE,
      user_id:   null,
    };

    projectIds.forEach((projectId, index) => {
      // eslint-disable-next-line
      window._top100q.push(() => {
        // eslint-disable-next-line
        this.top100Counters[projectId] = new window.top100({
          ...this.options,
          project: projectId,
        });

        if (index === 0) {
          // Первый счетчик принимаем за дефолтный
          window.top100Counter = this.top100Counters[projectId];
        }
      });
    });

    // eslint-disable-next-line
    window._top100q.push(() => {
      this.pushArray.forEach(fn => fn());
      this.pushArray = [];
      this.isLoaded = true;
    });
  },

  push(fn: () => any) {
    if (this.isLoaded) {
      fn();
    } else {
      this.pushArray.push(fn);
    }
  },

  sendCustomVars(vars: Record<string, any>) {
    if (isEmpty(vars)) {
      return;
    }

    this.push(() => {
      Object.keys(this.top100Counters).forEach(projectId => {
        this.top100Counters[projectId].sendCustomVars(vars);
      });
    });
  },

  trackPageview() {
    this.push(() => {
      Object.keys(this.top100Counters).forEach(projectId => {
        this.top100Counters[projectId].trackPageview();
      });
    });
  },

  sendUserId(userId?: string) {
    this.push(() => {
      Object.keys(this.top100Counters).forEach(projectId => {
        this.top100Counters[projectId].syncUserId(userId);
      });
    });
  },

  addSplit(split: string) {
    if (!split || this.options.splits.includes(split)) {
      return;
    }

    this.options.splits.push(split);

    this.push(() => {
      Object.keys(this.top100Counters).forEach(projectId => {
        this.top100Counters[projectId].updateOptions({
          splits: this.options.splits,
        });
      });
    });
  },
};

export default top100Wrapper;
