import Cookies from 'js-cookie';

export const setRswitch = (type: 'desktop' | 'mobile') => {
  Cookies.set('rswitch', type, { domain: '.rambler.ru' });
  window.location.reload();
};

export const setRswitchDesktop = () => {
  setRswitch('desktop');
};

export const setRswitchMobile = () => {
  setRswitch('mobile');
};
