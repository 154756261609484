import Cookies from 'js-cookie';

// TODO: Переделать механизм рефреша без обновления страницы (на клиенте)

/**
  * Механизм перезагрузки страницы при отсутствии пользователя на ней
  * через заданный таймаут
 */
class SleepRefresh {
  /**
    * время ухода со страницы
    */
  private leaveTime: number = 0;

  /**
    * время, после которого производится рефреш (сек)
    */
  private TIMEOUT: number = 120;

  /**
   * страницы, на которых запрещен авторефреш
   */
  private EXCLUDE_PAGES = [
    '/gadanie-online/taro-keltskiy-krest/',
    '/gadanie-online/taro-keltskiy-krest',
    '/taro/online/',
    '/taro/online',
    '/taro/budushchee/',
    '/taro/budushchee',
    '/taro/lyubov/',
    '/taro/lyubov',
    '/taro/da-net/',
    '/taro/da-net',
    '/taro/data-rozhdeniya/',
    '/taro/data-rozhdeniya',
    '/gadanie-online/gadanie-na-runah/',
    '/gadanie-online/gadanie-na-runah',
  ];

  /**
    * Прокрутка страницы в начало
    */
  // eslint-disable-next-line class-methods-use-this
  private stopScroll = () => {
    window.scrollTo(0, 0);
    document.body.style.overflow = 'hidden';
  };

  /**
    * Проверка куки отключения рефреша
    */
  // eslint-disable-next-line class-methods-use-this
  private isDisabled = () => Cookies.get('sleep_refresh') === 'disabled';

  // eslint-disable-next-line class-methods-use-this
  private getTimestamp = () => Math.ceil((new Date()).getTime() / 1000);

  private refresh = () => {
    const {
      origin,
      pathname,
      search,
      hash,
    } = document.location;

    if (new RegExp(this.EXCLUDE_PAGES.join('|')).test(pathname)) return;

    const isSlashEnd = pathname[pathname.length - 1] === '/';
    const link = origin + (isSlashEnd ? pathname : `${pathname}/`) + search;

    if (window.history && window.history.replaceState) {
      if (link.slice(-7) !== 'updated') {
        const urlWithChar = link.indexOf('?') === -1 ? `${link}?` : `${link}&`;
        const url = link.indexOf('updated') === -1 // чтобы избежать ?updated&page=1&updated
          ? `${urlWithChar}updated${hash}`
          : `${link}${hash}`;
        const stateData = { path: url, scrollTop: 0 };

        window.history.replaceState(stateData, 'title', url);
        this.stopScroll();
        document.location.reload();
      } else {
        const url = `${link}${hash}`;
        const stateData = { path: url, scrollTop: 0 };

        window.history.replaceState(stateData, 'title', url);
        this.stopScroll();
        document.location.reload();
      }
    } else if (link.slice(-7) !== 'updated') {
      const urlWithChar = link.indexOf('?') === -1 ? `${link}?` : `${link}&`;
      const url = `${urlWithChar}updated${hash}`;
      this.stopScroll();
      document.location.href = url;
    } else {
      this.stopScroll();
      document.location.href = `${link}${hash}`;
    }
  };

  /**
    * Активация рефреша
    */
  initialize() {
    if (!this.isDisabled()) {
      this.leaveTime = 0;

      if (document.hidden) {
        this.leaveTime = this.getTimestamp();
      }

      document.addEventListener('visibilitychange', () => {
        if (document.hidden) {
          this.leaveTime = this.getTimestamp();
        } else {
          const time = this.getTimestamp();
          const timeout = this.leaveTime;

          if (time - timeout > this.TIMEOUT) {
            this.refresh();
          }
        }
      });
    }
  }
}

if (__BROWSER__) {
  const refresh = new SleepRefresh();
  refresh.initialize();
}
