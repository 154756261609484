import { months } from 'config/constants/routes';
import { IAppRoute } from 'typings/AppRoute';

import { fetchPageData } from 'common/redux/pages';

import DefaultPage from 'desktop/pages/Default';

export const gardenRoutes: IAppRoute[] = [
  {
    name:      'garden-main',
    path:      '/sadovod',
    exact:     true,
    component: DefaultPage,
    fetchData: async ({ dispatch }) => {
      await dispatch(fetchPageData('garden', 'main', undefined, false, {
        top100:         'sadovod_main',
        isBubblesMoved: true,
      }));
    },
  },
  {
    name:      'garden-period',
    path:      `/sadovod/:period(${months})`,
    exact:     true,
    component: DefaultPage,
    fetchData: async ({ dispatch }, { params }) => {
      const { period } = params;

      await dispatch(fetchPageData('garden', 'period', {
        url:          period,
        gardenPeriod: period,
      }, false, {
        top100:         `sadovod_period_${period}`,
        isBubblesMoved: true,
      }));
    },
  },
];
