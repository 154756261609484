import { YANDEX_METRICS } from 'config/constants/counters';
import { APP_VERSION } from 'config/constants';

import top100Wrapper from 'utils/counters/top100Wrapper';
import { sentryReactSend } from 'utils/sentry/client';

let referer = __CLIENT__
  ? `${window.location.pathname}${window.location.search}${window.location.hash}`
  : '';

const updateCounters = (version: APP_VERSION) => {
  const {
    pathname,
    search,
    hash,
  } = window.location;
  const url = `${pathname}${search}${hash}`;

  if (window.ga) {
    window.ga('send', 'pageview');
  }

  try {
    top100Wrapper.trackPageview();
  } catch (error) {
    sentryReactSend(error);
  }

  const yaVersionVal = version === APP_VERSION.DESKTOP
    ? [YANDEX_METRICS.DESKTOP_UNIQUE]
    : [YANDEX_METRICS.MOBILE_UNIQUE, YANDEX_METRICS.MOBILE_PORTAL];

  const ids = [
    YANDEX_METRICS.COMMON,
    YANDEX_METRICS.PORTAL,
    ...yaVersionVal,
  ];

  if (window.ym) {
    ids.forEach(id => {
      const yaCounterId: string = `yaCounter${id}`;
      const counter = (window as { [key: string]: any })[yaCounterId];
      if (!counter) return;
      window.ym(id, 'hit', url, { referer });
    });
  }

  if (window.tnsCounterRambler_ru && window.tnsID) {
    window.tnsCounterRambler_ru.hit(window.tnsID, pathname);
  }
  referer = url;
};

export default updateCounters;
