import * as SentryReact from '@sentry/react';

import sentryFilters from 'config/constants/sentryFilters';
import { sendToSentry } from '../common';

/**
 * Инициализирует Sentry в браузере, некоторыми runtime
 */
export const initSentry = (runtime: RuntimeType) => {
  const {
    isMobile,
    config: {
      runProfile,
      sentryDSN,
      sentrySampleRate,
    },
    version,
    ruid,
  } = runtime;

  if (runProfile === 'local') return;

  SentryReact.init({
    ...sentryFilters,
    dsn:         sentryDSN,
    sampleRate:  sentrySampleRate,
    release:     version,
    environment: runProfile,
  });

  const scope = SentryReact.getCurrentScope();

  if (ruid) {
    scope.setUser({
      id: ruid,
    });
  }

  scope.setTag('app', 'client');
  scope.setTag('user_agent', window.navigator.userAgent);
  scope.setTag('is_mobile', String(isMobile));
  scope.setTag('viewport', String(window.innerWidth));
  scope.setTag('adblock_enabled', String(window.isAdblockEnabled));
  scope.setTag('anti_adblock_enabled', String(!!window.QW50aS1BZEJsb2Nr));
};

/**
 * Функция отправки ошибки с браузера с настраиваемым scope
 *
 * @param err - Ошибка типа `new Error()`
 * @param extraTags - Опционально. Тэги в виде объекта
 * @param modifyscope - Опционально. Callback типа `(scope) => {...}`
 */
export const sentryReactSend = sendToSentry(SentryReact);
