import appFetch from 'common/utils/fetch';

import { METRICS_ENDPOINT } from 'config/constants/metrics';

export const sendMetrics = (body: string, state?: IAppState) => {
  if (navigator.sendBeacon) {
    navigator.sendBeacon(METRICS_ENDPOINT, body);
    return;
  }

  if (state) {
    appFetch(METRICS_ENDPOINT, 'POST', state, undefined, body);
  } else {
    fetch(METRICS_ENDPOINT, {
      body,
      method:    'POST',
      keepalive: true,
    });
  }
};
