// @ts-nocheck
import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';

import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';

import RamblerTopline, { UserProfileType } from '@rambler-components/topline';
import Header from '@rambler-components/header';
import Popup from '@rambler-components/popup';
import Geo from '@rambler-components/geo';

import '@rambler-components/topline/styles.css';
import '@rambler-components/header/styles.css';

import { SET_RUNTIME_VARIABLE } from 'common/redux/runtime';
import { YANDEX_METRICS } from 'config/constants/counters';

import YandexEvent from 'utils/counters/YandexEvent';
import top100Wrapper from 'utils/counters/top100Wrapper';

import { Ad } from 'common/components/Ad';
import { Icon } from 'common/components/Icon';
import { getTop100Markup } from 'common/utils/getTop100Markup';
import { safeGet } from 'utils/safeGet';
import s from './index.css';

type ToplinePropsType = {
  top100Prefix: string
  withoutBorder?: boolean
};

const selectHeaderData = createSelector(
  [
    (state: IAppState) => state.header,
  ],
  header => ({
    header,
  }),
);

function ToplineDesktop({
  top100Prefix,
  withoutBorder,
}: ToplinePropsType) {
  const dispatch = useDispatch();
  const {
    header,
  } = useSelector(selectHeaderData);
  const {
    status,
    icon,
    title,
  } = header;

  const [userFetched, setUserFetched] = useState(false);
  const [userProfile, setUserProfile] = useState<UserProfileType | null>(null);
  const [geoOpened, setGeoOpened] = useState(false);
  const [reloadGeoKey, setReloadGeoKey] = useState(1);

  const statusText = `${status === 'neutral' ? 'Нейтрально' : ''}${status === 'lucky' ? 'Удачно' : ''}${status === 'unlucky' ? 'Неудачно' : ''}`;

  const onChangeLocation = useCallback(() => {
    setReloadGeoKey(prevGeoKey => prevGeoKey + 1);
  }, []);

  const onUserId = useCallback((userId?: string) => {
    dispatch({
      type:  SET_RUNTIME_VARIABLE,
      name:  'userId',
      value: userId,
    });
    top100Wrapper.sendUserId(userId);
  }, [dispatch]);

  const onUserInfo = useCallback((userInfo: UserProfileType) => {
    if (!userProfile) {
      dispatch({
        type:  SET_RUNTIME_VARIABLE,
        name:  'userEmail',
        value: safeGet(() => userInfo!.info.email, ''),
      });

      setUserProfile(userInfo);
    }
  }, [dispatch, userProfile]);

  const onUserFetched = useCallback(() => {
    setUserFetched(true);
  }, []);

  return (
    <div className="ad_branding_header">
      <RamblerTopline
        projectCode="horoscopes"
        redirectToMain={false}
        withoutShadow
        onUserFetched={onUserFetched}
        onUserId={onUserId}
        onUserInfo={onUserInfo}
        onUserState={e => {
          (new YandexEvent(YANDEX_METRICS.COMMON)).send({
            type:   'reachGoal',
            data:   `user_${e}`,
            params: {
              auth_type: e === 'login' ? 'ID_authorized' : 'ID_unauthorized',
            },
          }, () => {
            Cookies.remove('haccount');
            window.location.reload();
          });
        }}
        promoAd={<Ad name="topline_banner" />}
        scrollSticky
        onSticky={sticky => {
          dispatch({
            type:  SET_RUNTIME_VARIABLE,
            name:  'isStickyTopline',
            value: sticky,
          });
        }}
        zIndex={5003}
        onOpenGeo={() => setGeoOpened(true)}
        reloadGeoKey={reloadGeoKey}
      />

      <Popup width={560} zIndex={5005} isOpen={geoOpened} onClose={() => setGeoOpened(false)}>
        <Geo onChange={onChangeLocation} onClose={() => setGeoOpened(false)} />
      </Popup>

      <div className={cn(s.headerWrapper, withoutBorder && s.withoutBorder, 'search_bar')}>
        <Header
          projectCode="horoscopes"
          userFetched={userFetched}
          userProfile={userProfile}
          withoutShadow
        >
          <>
            {statusText && (
              <Link
                className={s.status}
                to="/hair/"
                {...getTop100Markup(false, top100Prefix, 'header_hair_status')}
                title={`${statusText} для стрижки`}
              >
                <Icon id={status} className={s.statusIcon} />
                <span className={s.statusText}>
                  {`${statusText} для стрижки`}
                </span>
              </Link>
            )}
            {icon && (
              <Link
                className={s.moon}
                to="/moon/calendar/"
                {...getTop100Markup(false, top100Prefix, 'header_moon')}
                title={title}
              >
                <span className={s.moonIconContainer}>
                  <Icon id={`header_${icon}`} className={s.moonIcon} />
                </span>
                <span className={s.moonText}>
                  {title}
                </span>
              </Link>
            )}
          </>
        </Header>
      </div>
    </div>
  );
}

ToplineDesktop.defaultProps = {
  withoutBorder: false,
};

export { ToplineDesktop };
