import { ExtendedError, Scope } from '@sentry/types';

/**
 * Принимает модуль сентри для клиента или сервера и возвращает функцию для отправки
 * ошибки на сервер сентри
 *
 * @param Sentry модуль сентри для клиента или сервера
 */
export const sendToSentry = (Sentry: any) => (
  err: ExtendedError,
  extraTags?: Record<string, string>,
  modifyScope?: (scope: Scope) => void,
) => {
  Sentry.withScope((scope: Scope) => {
    if (extraTags) {
      const tagEntries = Object.entries(extraTags);
      tagEntries.forEach(([tagKey, tagValue]) => {
        scope.setTag(tagKey, tagValue);
      });
    }
    if (modifyScope) {
      modifyScope(scope);
    }
    Sentry.captureException(err);
  });
};
