import React, { useState, useCallback, useMemo } from 'react';

import Footer from 'common/components/Footer';
import NewsLetterCards from 'common/components/NewsLetterSubscribe/SubscribeCards';
import SubscribePanel from 'common/components/NewsLetterSubscribe/SubscribePanel';

import { Top100Context } from 'common/contexts/top100Context';

import s from './index.css';

function NewsLetter() {
  const [selectLetters, setSelectLetters] = useState<string[]>([]);

  const top100Prefix = useMemo(() => ({ top100Prefix: 'news_letter_page' }), []);

  const handleSelect = useCallback((selectId: string) => {
    setSelectLetters(prev => (
      prev.includes(selectId)
        ? prev.filter(id => id !== selectId)
        : [...prev, selectId]));
  }, [setSelectLetters]);

  const handleReset = useCallback(() => {
    setSelectLetters([]);
  }, []);

  return (
    <div className={s.root}>
      <div className={s.content}>
        <h1 className={s.title}>Подпишитесь на судьбоносные рассылки Рамблер/гороскопов</h1>
        <p className={s.subTitle}>
          Выберите рассылки, которые помогут вам начать день правильно,
          узнать ваши сильные стороны и&nbsp;подготовиться к событиям дня
        </p>
        <Top100Context.Provider value={top100Prefix}>
          <NewsLetterCards selected={selectLetters} onSelect={handleSelect} />
        </Top100Context.Provider>
      </div>
      <Top100Context.Provider value={top100Prefix}>
        <SubscribePanel
          subscribeKeys={selectLetters}
          count={selectLetters.length}
          isVisible={selectLetters.length > 0}
          onReset={handleReset}
        />
      </Top100Context.Provider>
      <div className={s.footer}>
        <Footer />
      </div>
    </div>
  );
}

export default NewsLetter;
