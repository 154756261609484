import config from 'config';

import { insertScriptIntoHead } from 'utils/insertScriptIntoHead';
import { checkingXperias } from 'utils/checkingXperias';

/**
 * Инициализация воркеров
 *
 * @param runtime - общие данные о проекте
 */
export const initServiceWorker = (runtime: RuntimeType) => {
  const {
    config: {
      runProfile,
    },
  } = runtime;

  insertScriptIntoHead({
    src: '//cdn.pushwoosh.com/webpush/v3/pushwoosh-web-notifications.js',
  });

  try {
    const applicationCode = config.PUSHWOOSH.APPLICATION_CODE[runProfile];
    const defaultNotificationImage = config.PUSHWOOSH.DEFAULT_NOTIFICATION_IMAGE;
    const defaultNotificationTitle = `${config.PUSHWOOSH.DEFAULT_NOTIFICATION_TITLE}${runProfile === 'release' ? '' : ' (stage)'}`;
    const safariWebsitePushID = config.PUSHWOOSH.SAFARI_WEBSITE_PUSH_ID;
    const serviceWorkerUrl = __DEV__ || checkingXperias()
      ? '/rambler-service-worker.js'
      : '/service-worker.js';
    const pushWooshSettings = {
      logLevel:        config.PUSHWOOSH.LOG_LEVEL,
      applicationCode,
      safariWebsitePushID,
      defaultNotificationTitle,
      defaultNotificationImage,
      serviceWorkerUrl,
      autoSubscribe:   config.PUSHWOOSH.AUTOSUBSCRIBE,
      subscribeWidget: {
        enabled: false,
      },
      android_icon: '/pushwoosh-android-icon.png',
    };

    if ('serviceWorker' in window.navigator) {
      window.navigator.serviceWorker.register('/service-worker.js');
    }

    const initPushwoosh = () => {
      if (!window.Pushwoosh) {
        setTimeout(() => { initPushwoosh(); }, 5000);
        return;
      }
      window.Pushwoosh = window.Pushwoosh || [];
      if (!checkingXperias()) {
        window.Pushwoosh.push(['init', pushWooshSettings]);
        window.Pushwoosh.push(['onSubscribe', () => {
          // eslint-disable-next-line no-console
          console.warn('User successfully subscribed');
        }]);
      }
    };

    initPushwoosh();
  } catch (error) {
    console.warn('SW init error', error); // eslint-disable-line
  }
};
