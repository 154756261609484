import React from 'react';
import { useSelector } from 'react-redux';

import { CardHeader } from 'common/components/Card/Header';
import { Footer } from 'common/components/Card/Footer';

import { TableOfContents } from 'common/components/TableOfContents';
import { DraftText } from 'common/components/DraftText';
import { Form } from 'common/components/Card/Form';
import { Tags } from 'common/components/Card/Tags';
import parseDraft from 'common/utils/draftParser';
import Layout from 'desktop/components/Layout';

import { selectPageData } from 'utils/selectPageData';

import s from './styles.css';

function TarotCategoryPage() {
  const data = useSelector((state: IAppState) => selectPageData(state, 'fortune', 'tarocategory'));

  if (!data) return null;

  const { content } = data;
  const isMobile = false;

  const {
    title,
    link,
    likes,
    type,
    draft,
    tags,
    id,
  } = content;

  const draftParsed = draft
    ? parseDraft(
      draft,
      id,
      isMobile,
      undefined,
      true,
    )
    : '';

  return (
    <Layout
      withSchema
      withoutMainCard
      withoutBubbles
      withoutPersonal
      withTarotMenu
      centerColumn={(
        <div className={s.card}>
          <CardHeader
            isMobile={isMobile}
            titleProps={{
              title,
              isMobile,
              type,
              isLink:     false,
              isMainCard: true,
              isH1Title:  true,
              isHanding:  false,
            }}
            socialProps={{
              isMobile,
            }}
          />
          <Form
            form="search_taro"
            isMobile={isMobile}
            className={s.form}
          />
          <TableOfContents
            draft={draft}
            isMobile={isMobile}
            className={s.tableOfContents}
          />
          <DraftText
            data={draftParsed}
            title={title}
            className={s.draftText}
            isMainCard
          />
          <Tags
            wrapperClassName={s.tags}
            tags={tags}
            isMobile={isMobile}
          />
          <Footer
            title={title}
            link={link}
            likes={likes}
            isMobile={isMobile}
            className={s.footer}
            key={likes ? likes.codename : ''}
          />
        </div>
      )}
    />
  );
}

export default TarotCategoryPage;
