import React from 'react';
import loadable from '@loadable/component';
import { IAppRoute } from 'typings/AppRoute';

import { fetchPageData } from 'common/redux/pages';

import TarotCardsPage from 'desktop/pages/Tarot/Cards';
import TarotCategoryPage from 'desktop/pages/Tarot/Category';
import TarotCardPage from 'desktop/pages/Tarot/Card';
import TarotMainPage from 'desktop/pages/Tarot/Main';
import TarotOnlinePage from 'desktop/pages/Tarot/Online';
import TarotGuidePage from 'desktop/pages/Tarot/Guide';
import DefaultPage from 'desktop/pages/Default';
import {
  CODES_BY_CATEGORY,
  getCurrentTarot,
  tarotCardSlugs,
  tarotCategories,
  TAROT_FORECAST_YEARS,
  CODENAMES_BY_TYPE,
} from 'config/constants/tarot';

import { fetchTarotData } from 'common/redux/tarot';
import { fetchLikesData } from 'common/redux/likes';
import { zodiacSigns } from 'config/constants/routes';

const GamePage = loadable(() => import('desktop/pages/Game'));

export const tarotRoutes: IAppRoute[] = [
  {
    name:      'fortune-taro-main',
    path:      '/taro',
    exact:     true,
    component: TarotMainPage,
    fetchData: async ({ dispatch }) => {
      await Promise.all([
        dispatch(fetchPageData('fortune', 'taromain', undefined, false, {
          top100: 'tarot_main',
        })),
        dispatch(fetchTarotData('today')),
      ]);
    },
  },
  {
    name:      'fortune-taro-online',
    path:      '/taro/online',
    exact:     true,
    component: TarotOnlinePage,
    fetchData: async ({ dispatch }) => {
      await Promise.all([
        dispatch(fetchPageData('fortune', 'taroonline', undefined, false, {
          top100: 'tarot_fortune_main',
        })),
        dispatch(fetchLikesData(Object.values(CODENAMES_BY_TYPE).join(','))),
      ]);
    },
  },
  {
    name:      'fortune-taro-future',
    path:      '/taro/budushchee',
    exact:     true,
    component: GamePage,
    fetchData: async ({ dispatch }) => {
      await dispatch(fetchPageData('fortune', 'tarofuture', undefined, false, {
        top100:         'tarot_fortune_future',
        isBubblesMoved: true,
      }));
    },
  },
  {
    name:      'fortune-taro-future',
    path:      '/taro/budushchee/:key/card-:card',
    exact:     true,
    component: GamePage,
    fetchData: async ({ dispatch }, { params }) => {
      const { key, card } = params;

      await dispatch(fetchPageData('fortune', 'tarofuture', {
        url:            '',
        specialKey:     key,
        specialCurrent: card,
      }, false, {
        top100:         'tarot_fortune_future',
        isBubblesMoved: true,
      }));
    },
  },
  {
    name:      'fortune-taro-love',
    path:      '/taro/lyubov',
    exact:     true,
    component: GamePage,
    fetchData: async ({ dispatch }) => {
      await dispatch(fetchPageData('fortune', 'tarolove', undefined, false, {
        top100:         'tarot_fortune_love',
        isBubblesMoved: true,
      }));
    },
  },
  {
    name:      'fortune-taro-love',
    path:      '/taro/lyubov/:key/card-:card',
    exact:     true,
    component: GamePage,
    fetchData: async ({ dispatch }, { params }) => {
      const { key, card } = params;

      await dispatch(fetchPageData('fortune', 'tarolove', {
        url:            '',
        specialKey:     key,
        specialCurrent: card,
      }, false, {
        top100:         'tarot_fortune_love',
        isBubblesMoved: true,
      }));
    },
  },
  {
    name:      'fortune-taro-short',
    path:      '/taro/da-net',
    exact:     true,
    component: GamePage,
    fetchData: async ({ dispatch }) => {
      await dispatch(fetchPageData('fortune', 'taroshort', undefined, false, {
        top100:         'tarot_fortune_yes_no',
        isBubblesMoved: true,
      }));
    },
  },
  {
    name:      'fortune-taro-short',
    path:      '/taro/da-net/:key/card-:card',
    exact:     true,
    component: GamePage,
    fetchData: async ({ dispatch }, { params }) => {
      const { key, card } = params;

      await dispatch(fetchPageData('fortune', 'taroshort', {
        url:            '',
        specialKey:     key,
        specialCurrent: card,
      }, false, {
        top100:         'tarot_fortune_yes_no',
        isBubblesMoved: true,
      }));
    },
  },
  {
    name:      'fortune-taro-birthdate',
    path:      '/taro/data-rozhdeniya',
    exact:     true,
    component: GamePage,
    fetchData: async ({ dispatch }) => {
      await dispatch(fetchPageData('fortune', 'tarobirthdate', undefined, false, {
        top100: 'tarot_fortune_birthdate',
      }));
    },
  },
  {
    name:      'fortune-taro-birthdate',
    path:      '/taro/data-rozhdeniya/:key/card-:card',
    exact:     true,
    component: GamePage,
    fetchData: async ({ dispatch }, { params }) => {
      const { key, card } = params;

      await dispatch(fetchPageData('fortune', 'tarobirthdate', {
        url:            '',
        specialKey:     key,
        specialCurrent: card,
      }, false, {
        top100: 'tarot_fortune_birthdate',
      }));
    },
  },
  {
    name:      'fortune-taro-guide',
    path:      '/taro/samostoyatelno',
    exact:     true,
    component: TarotGuidePage,
    fetchData: async ({ dispatch }) => {
      await dispatch(fetchPageData('fortune', 'taroguide', undefined, false, {
        top100: 'tarot_guide',
      }));
    },
  },
  {
    name:      'fortune-taro-cards',
    path:      '/taro/znachenie-kart',
    exact:     true,
    component: TarotCardsPage,
    fetchData: async ({ dispatch }) => {
      await dispatch(fetchPageData('fortune', 'tarocards', undefined, false, {
        top100: 'tarot_meaning_main',
      }));
    },
  },
  {
    name:      'fortune-taro-category',
    path:      `/taro/znachenie-kart/:category(${tarotCategories})`,
    exact:     true,
    component: TarotCategoryPage,
    fetchData: async ({ dispatch }, { params }) => {
      const { category } = params;
      const url = CODES_BY_CATEGORY[category as keyof typeof CODES_BY_CATEGORY];

      await dispatch(fetchPageData('fortune', 'tarocategory', { url }, false, {
        top100: 'tarot_meaning_suit',
      }));
    },
  },
  {
    name:      'fortune-taro-one-card',
    path:      `/taro/znachenie-kart/:slug(${tarotCardSlugs})`,
    exact:     true,
    component: TarotCardPage,
    fetchData: async ({ dispatch }, { params }) => {
      const { slug } = params;
      const { code } = getCurrentTarot('slug', slug) || {};
      await dispatch(fetchPageData('fortune', 'taroonecard', { url: code }, false, {
        top100: 'tarot_meaning_card',
      }));
    },
  },
  {
    name:      'fortune-taro-forecast',
    path:      `/taro/goroskop/:year(${TAROT_FORECAST_YEARS})?`,
    exact:     true,
    render:    props => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }, { params }) => {
      const currentYear = new Date().getFullYear();
      const { year } = params;

      await dispatch(fetchPageData('fortune', 'taroforecast', { url: year || currentYear }, false, {
        top100: 'tarot_horo',
      }));
    },
  },
  {
    name:      'fortune-taro-forecast-aries',
    path:      `/taro/goroskop/:sign(${zodiacSigns})/:year(${TAROT_FORECAST_YEARS})?`,
    exact:     true,
    render:    props => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }, { params }) => {
      const currentYear = new Date().getFullYear();
      const { sign, year = currentYear } = params;

      await dispatch(fetchPageData('fortune', 'taroforecastbysign', {
        url:        `${sign}/${year}`,
        zodiacSign: sign,
      }, false, {
        top100: 'tarot_horo',
      }));
    },
  },
];
