import React from 'react';

import {
  chineseSigns,
  chineseSignsAll,
  chineseYears,
} from 'config/constants/routes';
import signNames from 'config/constants/signNames';

import { fetchPageData } from 'common/redux/pages';

import { IAppRoute } from 'typings/AppRoute';

import DefaultPage from 'desktop/pages/Default';

export const chineseRoutes: IAppRoute[] = [
  // Main
  {
    name:      'chinese',
    path:      '/chinese',
    exact:     true,
    render:    props => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }) => {
      await dispatch(fetchPageData('chinese', 'description', {
        url: 'all',
      }, false, {
        top100:         'chinese_main_page',
        isBubblesMoved: true,
      }));
    },
  },
  // Description
  {
    name:      'chineseDescription',
    path:      `/chinese/description/:sign(${chineseSigns})`,
    exact:     true,
    render:    props => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }, { params }) => {
      const { sign } = params;
      await dispatch(fetchPageData('chinese', 'description', {
        url:         sign,
        chineseSign: sign,
      }, false, {
        top100:         'chinese_description_sign_page',
        isBubblesMoved: true,
      }));
    },
  },
  // Compatibility
  {
    name:      'chineseCompatibility',
    path:      `/chinese/compatibility/:sign(${chineseSignsAll})`,
    exact:     true,
    render:    props => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }, { params }) => {
      const { sign } = params;
      const isAllPage = sign === 'all';

      await dispatch(fetchPageData('chinese', 'compatibility', {
        url:                      sign,
        chineseCompatibilitySign: sign,
      }, false, {
        top100:         isAllPage ? 'chinese_compatibility_all' : 'chinese_compatibility_one_sign',
        isBubblesMoved: true,
      }));
    },
  },
  // Compatibility by signs
  {
    name:      'chineseCompatibilityPairs',
    path:      '/chinese/compatibility/muzhchina-:man-zhenshchina-:woman',
    exact:     true,
    render:    props => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }, { params }) => {
      const { woman, man } = params as {
        woman: keyof typeof signNames.chineseLatin
        man: keyof typeof signNames.chineseLatin
      };
      await dispatch(fetchPageData('chinese', 'compatibility', {
        url:                           `woman-${signNames.chineseLatin[woman]}/man-${signNames.chineseLatin[man]}`,
        chineseCompatibilityManSign:   signNames.chineseLatin[man],
        chineseCompatibilityWomanSign: signNames.chineseLatin[woman],
      }, false, {
        top100:         'chinese_compatibility_2_signs',
        isBubblesMoved: true,
      }));
    },
  },
  // Forecast
  {
    name:      'chineseForecast',
    path:      `/chinese/forecast/:sign(${chineseSignsAll})/:year(${chineseYears})`,
    exact:     true,
    render:    props => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }, { params }) => {
      const { sign, year } = params;
      await dispatch(fetchPageData('chinese', 'forecast', {
        url:         `${sign}/${year}`,
        chineseSign: sign,
        chineseYear: year,
      }, false, {
        top100:         'chinese_forecast_page',
        isBubblesMoved: true,
      }));
    },
  },
];
