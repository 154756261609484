import React from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';

import { parseDraft } from 'common/utils/draftParser';

import { DraftText } from 'common/components/DraftText';
import { CardHeader } from 'common/components/Card/Header';
import { Tags } from 'common/components/Card/Tags';
import { Footer } from 'common/components/Card/Footer';
import { HumanDesignTypes } from 'common/components/HumanDesign/Types';
import { HumanDesignProfiles } from 'common/components/HumanDesign/Profiles';
import { HumanDesignForm } from 'common/components/HumanDesign/Form';
import { HumanDesignStructure } from 'common/components/HumanDesign/Structure';
import { Ad } from 'common/components/Ad';
import Layout from 'desktop/components/Layout';

import { selectPageData } from 'utils/selectPageData';

import s from './index.css';

const selectData = createSelector(
  [
    (state: IAppState) => state.humanDesign,
  ],
  humanDesign => ({
    humanDesign,
  }),
);

function HumanDesignMainPage() {
  const {
    humanDesign,
  } = useSelector(selectData);

  const data = useSelector((state: IAppState) => selectPageData(state, 'humandesign', 'main'));

  if (!humanDesign || !data) return null;

  const { content } = data;
  const isMobile = false;

  const {
    title,
    link,
    type,
    is_h2_title: isH2Title,
    is_h3_title: isH3Title,
    isMainCard,
    isSubCard,
    tags,
    likes,
    id,
    draft,
  } = content;

  const draftParsed = draft
    ? parseDraft(
      draft,
      id,
      isMobile,
    )
    : '';
  const cardText = (
    <DraftText
      data={draftParsed}
      title={title}
      className={cn(
        s.draftText,
        s.draftTextDesktop,
      )}
      isMainCard
    />
  );

  return (
    <Layout
      withSchema
      withoutMainCard
      centerColumn={(
        <div className={s.card}>
          <CardHeader
            isMobile={isMobile}
            titleProps={{
              title,
              isMobile,
              link,
              type,
              isLink:    false,
              isH2Title,
              isH3Title,
              isMainCard,
              isH1Title: true,
              isHanding: false,
              isSubCard,
            }}
            socialProps={{
              links: {},
              isMobile,
            }}
          />
          <HumanDesignForm
            className={s.form}
            isMainPage
            isMobile={isMobile}
            isExperimental={false}
          />
          <Ad
            name="center"
            wrapperClassName={s.ad}
          />
          <HumanDesignStructure />
          <HumanDesignTypes
            types={humanDesign.types}
            limit={4}
            isMobile={isMobile}
          />
          <Ad
            name="inread"
            wrapperClassName={s.ad}
          />
          <HumanDesignProfiles
            profiles={humanDesign.profiles}
            limit={4}
            isMobile={isMobile}
          />
          {cardText}
          <Tags
            wrapperClassName={s.tags}
            tags={tags}
            isMobile={isMobile}
          />
          <Footer
            className={cn(
              s.footer,
              s.footerDesktop,
            )}
            likesClassName={cn(
              s.likes,
              s.likesDesktop,
            )}
            title={title}
            link={link}
            likes={likes}
            isMobile={isMobile}
            key={likes ? likes.codename : ''}
          />
        </div>
      )}
    />
  );
}

export default HumanDesignMainPage;
