import {
  METRICS_DURATION_QUEUE,
} from 'config/constants/metrics';

import { sendMetrics } from './sendMetrics';

type Metric = {
  name: string,
  value: Record<string, string | number>,
};

interface ClientMetricsBatch {
  queue: Set<Metric>;
  pageName?: string;
  pageSection?: string;
  duration?: number;
  timeoutId?: NodeJS.Timeout;
  init(opts: {
    pageName: string,
    pageSection: string,
    duration?: number,
    sampleRate?: number,
  }): void;
  add(metric: Metric): void;
  startWaitingForFlushing(): void;
  flushQueue(): void;
  beforeUnload(): void;
}

export const clientMetricsBatch: ClientMetricsBatch = {
  queue: new Set<Metric>(),

  init({
    pageName,
    pageSection,
    duration = METRICS_DURATION_QUEUE,
    sampleRate = 0.1,
  }) {
    this.pageName = pageName;
    this.pageSection = pageSection;
    this.duration = duration;

    if (Math.random() <= sampleRate) {
      this.startWaitingForFlushing();
    }
  },

  startWaitingForFlushing() {
    if (!this.duration) {
      return;
    }

    this.timeoutId = setTimeout(this.flushQueue.bind(this), this.duration);

    window.addEventListener('beforeunload', this.beforeUnload.bind(this));
  },

  flushQueue() {
    if (this.queue.size > 0) {
      const body = JSON.stringify({
        pageName:    this.pageName,
        pageSection: this.pageSection,
        metrics:     [...this.queue],
      });

      sendMetrics(body);
    }

    this.queue.clear();

    this.startWaitingForFlushing();
  },

  beforeUnload() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    this.flushQueue();
  },

  add(metric: Metric) {
    this.queue.add(metric);
  },
};
