import React from 'react';

import {
  date,
  sunPeriods,
} from 'config/constants/routes';

import { fetchPageData } from 'common/redux/pages';

import { IAppRoute } from 'typings/AppRoute';

import DefaultPage from 'desktop/pages/Default';

export const sunRoutes: IAppRoute[] = [
  {
    name:      'sun-main',
    path:      '/sun/calendar',
    exact:     true,
    render:    props => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }) => {
      await dispatch(fetchPageData('sun', 'calendar', {
        url: 'today',
      }, false, {
        top100:         'sunday_main',
        isBubblesMoved: true,
      }));
    },
  },
  {
    name:      'sun-period',
    path:      `/sun/calendar/:period(${sunPeriods})`,
    exact:     true,
    render:    props => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }, { params }) => {
      const { period } = params;

      await dispatch(fetchPageData('sun', 'calendar', {
        url:       period,
        sunPeriod: period,
      }, false, {
        top100:         `sunday_period_${period}`,
        isBubblesMoved: true,
      }));
    },
  },
  {
    name:      'sun-date',
    path:      `/sun/calendar/:year(${date.year})-:month(${date.month})-:day(${date.day})`,
    exact:     true,
    render:    props => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }, { params }) => {
      const {
        year,
        month,
        day,
      } = params;

      await dispatch(fetchPageData('sun', 'calendar', {
        url:     `${year}-${month}-${day}`,
        sunDate: `${year}-${month}-${day}`,
      }, false, {
        top100:         'sunday_period_date',
        isBubblesMoved: true,
      }));
    },
  },
];
