import qs from 'querystring';

import { humanDesignTypes, humanDesignProfiles } from 'config/constants/routes';
import { IAppRoute } from 'typings/AppRoute';

import { fetchPageData } from 'common/redux/pages';
import {
  fetchHumanDesignData,
  fetchHumanDesignDetailedData,
  fetchHumanDesignGeoData,
  fetchHumanDesignResultData,
} from 'common/redux/humanDesign';

import HumanDesignMainPage from 'desktop/pages/HumanDesign/Main';
import HumanDesignProfilesPage from 'desktop/pages/HumanDesign/Profiles';
import HumanDesignTypesPage from 'desktop/pages/HumanDesign/Types';
import HumanDesignProfilePage from 'desktop/pages/HumanDesign/Profile';
import HumanDesignTypePage from 'desktop/pages/HumanDesign/Type';
import HumanDesignResultPage from 'desktop/pages/HumanDesign/Result';

export const humanDesignRoutes: IAppRoute[] = [
  {
    name:      'humandesign-main',
    path:      '/dizain-cheloveka',
    exact:     true,
    component: HumanDesignMainPage,
    fetchData: async ({ dispatch }) => {
      await Promise.all([
        dispatch(fetchPageData('humandesign', 'main', undefined, false, {
          top100: 'dizain-cheloveka_main',
        })),
        dispatch(fetchHumanDesignData('types')),
        dispatch(fetchHumanDesignData('profiles')),
      ]);
    },
  },
  {
    name:      'humandesign-types',
    path:      '/dizain-cheloveka/tipy',
    exact:     true,
    component: HumanDesignTypesPage,
    fetchData: async ({ dispatch }) => {
      await Promise.all([
        dispatch(fetchPageData('humandesign', 'types', undefined, false, {
          top100: 'dizain-cheloveka_types',
        })),
        dispatch(fetchHumanDesignData('types')),
        dispatch(fetchHumanDesignData('profiles')),
      ]);
    },
  },
  {
    name:      'humandesign-type',
    path:      `/dizain-cheloveka/tipy/:type(${humanDesignTypes})`,
    exact:     true,
    component: HumanDesignTypePage,
    fetchData: async ({ dispatch }, { params }) => {
      const { type } = params;

      await Promise.all([
        dispatch(fetchPageData('humandesign', 'type', {
          url:                 type,
          humanDesignDetailed: type,
        }, false, {
          top100: 'dizain-cheloveka_types',
        })),
        dispatch(fetchHumanDesignData('types')),
        dispatch(fetchHumanDesignData('profiles')),
        dispatch(fetchHumanDesignDetailedData('types', type)),
      ]);
    },
  },
  {
    name:      'humandesign-profiles',
    path:      '/dizain-cheloveka/profili',
    exact:     true,
    component: HumanDesignProfilesPage,
    fetchData: async ({ dispatch }) => {
      await Promise.all([
        dispatch(fetchPageData('humandesign', 'profiles', undefined, false, {
          top100: 'dizain-cheloveka_profiles',
        })),
        dispatch(fetchHumanDesignData('types')),
        dispatch(fetchHumanDesignData('profiles')),
        dispatch(fetchHumanDesignData('lines')),
      ]);
    },
  },
  {
    name:      'humandesign-profile',
    path:      `/dizain-cheloveka/profili/:profile(${humanDesignProfiles})`,
    exact:     true,
    component: HumanDesignProfilePage,
    fetchData: async ({ dispatch }, { params }) => {
      const { profile } = params;

      await Promise.all([
        dispatch(fetchPageData('humandesign', 'profile', {
          url:                 profile,
          humanDesignDetailed: profile,
        }, false, {
          top100: 'dizain-cheloveka_profiles',
        })),
        dispatch(fetchHumanDesignData('types')),
        dispatch(fetchHumanDesignData('profiles')),
        dispatch(fetchHumanDesignDetailedData('profiles', profile)),
      ]);
    },
  },
  {
    name:      'humandesign-result',
    path:      '/dizain-cheloveka/karta',
    exact:     true,
    component: HumanDesignResultPage,
    fetchData: async ({ dispatch }, _, queryParams) => {
      const query = __SERVER__ ? queryParams : qs.parse(window.location.search.slice(1));
      const { date, timezone, place } = query || {};
      const urlParams = `date=${date}&timezone=${timezone}&place=${place}`;

      await Promise.all([
        dispatch(fetchPageData('humandesign', 'result', {
          url:             '',
          humanDesignDate: date?.toString(),
        }, false, {
          top100: 'dizain-cheloveka_result',
        })),
        dispatch(fetchHumanDesignData('types')),
        dispatch(fetchHumanDesignData('profiles')),
        dispatch(fetchHumanDesignResultData(urlParams)),
        dispatch(fetchHumanDesignGeoData('places', 'details', `placeid=${place}`)),
      ]);
    },
  },
];
