import { longreadTopicsList } from 'config/constants/routes';

import { fetchPageData } from 'common/redux/pages';

import { IAppRoute } from 'typings/AppRoute';

import DefaultPage from 'desktop/pages/Default';
import LongreadDetailed from 'desktop/pages/LongreadDetailed';

export const longreadRoutes: IAppRoute[] = [
  {
    name:      'articles-list',
    path:      '/longread',
    exact:     true,
    component: DefaultPage,
    fetchData: async ({ dispatch }) => {
      await dispatch(fetchPageData('articles', 'list', undefined, false, { // при изменении 'articles' или 'list' изменить начальное значение nextPage
        top100: 'longread_main',
      }));
    },
  },
  {
    name:      'articles-rubric',
    path:      `/:topic(${longreadTopicsList})`,
    exact:     true,
    component: DefaultPage,
    fetchData: async ({ dispatch }, { params }) => {
      const { topic } = params;
      await dispatch(fetchPageData('articles', 'topic', {
        url:           topic,
        longreadTopic: topic,
      }, false, { // при изменении 'articles' или 'list' изменить начальное значение nextPage
        top100: 'longread_rubrics',
      }));
    },
  },
  {
    name:      'articles-detail',
    path:      `/:topic(${longreadTopicsList})/:id-:slug`,
    exact:     true,
    component: LongreadDetailed,
    fetchData: async ({ dispatch }, { params }) => {
      const { topic, id } = params;

      await dispatch(fetchPageData('articles', 'detail', {
        url:           `${id}/${topic}`,
        longreadId:    id,
        longreadTopic: topic,
      }, false, {
        top100: 'longread_article',
      }));
    },
  },
];
