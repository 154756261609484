import { safeGet } from 'utils/safeGet';

type UtmParamsType = {
  source?: string
  medium?: string
  campaign?: string
  content?: string
  term?: string
};

/**
 * Принимает url и возвращает url с utm-метками, модифицирует уже существующие
 * параметры (если они есть).
 *
 * Usage: getWithUtmParams('https://google.com', {
 *   medium: 'social',
 *   compaign: 'buffer',
 * })
 *
 * @param url URL, можеть быть `string` или `new URL(...)`
 * @param params UTM-метки в виде объекта типа `{medium: 'hello', ...}`
 * @param deleteInitialUtm - удаление всех изначальных UTM-меток
 */
export const getUrlWithUtmParams = (
  url: string | URL,
  params: UtmParamsType,
  deleteInitialUtm: boolean = false,
): string => {
  let replaceProtocol = false;
  let urlObj = safeGet(() => (typeof url === 'string' ? new URL(url) : url));

  if (
    !urlObj
    && typeof url === 'string'
    && !url.includes('http://')
    && !url.includes('https://')
  ) {
    replaceProtocol = true;
    const urlWithReplacedSymbols = url.replace(/\s/g, '+').replace('://', '');
    urlObj = safeGet(() => new URL(`http://${urlWithReplacedSymbols}`));
  }

  if (!urlObj) return `${url}`;

  const queryParams = safeGet(() => new URLSearchParams(urlObj!.search.slice(1)));

  if (!queryParams) return `${url}`;

  const urlWithoutParams = replaceProtocol ? (
    urlObj.toString().replace(/http:\/\//, '').replace(/\?.+/, '')
  ) : (
    urlObj.toString().replace(/\?.+/, '')
  );

  if (deleteInitialUtm) {
    // @ts-ignore
    [...queryParams.keys()].forEach(key => queryParams.delete(key));
  }

  Object.entries(params).forEach(([key, value]) => {
    if (value) {
      queryParams.set(`utm_${key}`, value);
    }
  });

  return `${urlWithoutParams}?${queryParams}`;
};

const DOMAIN_STUB = 'https://horoscopes.rambler.ru';

const replaceUpdated = (str: string) => str.replace('updated=', 'updated');

/**
 * Изменение get-параметров в url
 *
 * @param url - URL, можеть быть `string` или `new URL(...)`
 * @param params - объект с ключами-get-параметрами и значениями для изменения
 * @param withDomain - вернуть урл с доменом или без
 *
 * @example
 * changeGetParams(url, { update: '', article_index: '1' })
 * @returns url с измененными get-параметрами
 */
export const changeGetParams = (
  url: string | URL,
  params: { [getParam: string]: string },
  withDomain: boolean = false,
): string => {
  const urlObj = safeGet(() => (
    typeof url === 'string'
      ? safeGet(() => new URL(url), new URL(url, DOMAIN_STUB))
      : url
  ));

  if (!urlObj) return '';

  Object.keys(params).forEach(key => {
    const newValue = params[key];
    urlObj.searchParams.set(key, newValue);
  });

  if (withDomain) return replaceUpdated(urlObj.toString());

  return `${urlObj.pathname}${replaceUpdated(urlObj.search)}${urlObj.hash}`;
};
