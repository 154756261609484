import React from 'react';
import { useSelector } from 'react-redux';

import Layout from 'desktop/components/Layout';
import { CardHeader } from 'common/components/Card/Header';
import { Footer } from 'common/components/Card/Footer';
import { Tags } from 'common/components/Card/Tags';

import { Form } from 'common/components/Card/Form';
import { TarotCards } from 'common/components/Tarot/TarotCards';

import { selectPageData } from 'utils/selectPageData';

import s from './styles.css';

function TarotCardsPage() {
  const data = useSelector((state: IAppState) => selectPageData(state, 'fortune', 'tarocards'));

  if (!data) return null;

  const { content } = data;
  const isMobile = false;

  const {
    title,
    link,
    likes,
    type,
    tags,
  } = content;

  return (
    <Layout
      withSchema
      withoutMainCard
      withoutBubbles
      withoutPersonal
      withTarotMenu
      centerColumn={(
        <div className={s.card}>
          <CardHeader
            isMobile={isMobile}
            titleProps={{
              title:      'Значение карт Таро',
              isMobile,
              type,
              isLink:     false,
              isMainCard: true,
              isH1Title:  true,
              isHanding:  false,
            }}
            socialProps={{
              isMobile,
            }}
          />
          <Form
            form="search_taro"
            isMobile={isMobile}
            className={s.form}
          />
          <TarotCards isMobile={isMobile} />
          <Tags
            wrapperClassName={s.tags}
            tags={tags}
            isMobile={isMobile}
          />
          <Footer
            title={title}
            link={link}
            likes={likes}
            isMobile={isMobile}
            key={likes ? likes.codename : ''}
          />
        </div>
      )}
    />
  );
}

export default TarotCardsPage;
