import React from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';

import Layout from 'desktop/components/Layout';
import { CardHeader } from 'common/components/Card/Header';
import { Footer } from 'common/components/Card/Footer';
import { Tags } from 'common/components/Card/Tags';
import { Typography } from 'common/components/Typography';
import { HumanDesignTypes } from 'common/components/HumanDesign/Types';
import { HumanDesignLines } from 'common/components/HumanDesign/Lines';
import { HumanDesignForm } from 'common/components/HumanDesign/Form';

import { HUMAN_DESIGN_PROFILES_STATIC_TEXT } from 'config/constants/humanDesign';

import { selectPageData } from 'utils/selectPageData';

import s from './index.css';

const selectData = createSelector(
  [
    (state: IAppState) => state.humanDesign,
  ],
  humanDesign => ({
    humanDesign,
  }),
);

function HumanDesignProfilesPage() {
  const {
    humanDesign,
  } = useSelector(selectData);

  const data = useSelector((state: IAppState) => selectPageData(state, 'humandesign', 'profiles'));

  if (!humanDesign || !data) return null;

  const { content } = data;
  const isMobile = false;

  const {
    title,
    link,
    type,
    is_h2_title: isH2Title,
    is_h3_title: isH3Title,
    isMainCard,
    isSubCard,
    tags,
    likes,
  } = content;

  return (
    <Layout
      withSchema
      withoutMainCard
      centerColumn={(
        <div className={s.card}>
          <CardHeader
            isMobile={isMobile}
            titleProps={{
              title,
              isMobile,
              link,
              type,
              isLink:    false,
              isH2Title,
              isH3Title,
              isMainCard,
              isH1Title: true,
              isHanding: false,
              isSubCard,
            }}
            socialProps={{
              links: {},
              isMobile,
            }}
          />
          <Typography
            variant="defaultRegular"
            component="p"
            className={s.text}
          >
            {HUMAN_DESIGN_PROFILES_STATIC_TEXT}
          </Typography>
          <HumanDesignLines
            profiles={humanDesign.profiles}
            lines={humanDesign.lines}
            ads={{
              0: 'center',
              3: 'inread',
            }}
            isMobile={isMobile}
          />
          <HumanDesignTypes
            types={humanDesign.types}
            limit={2}
            isMobile={isMobile}
          />
          <HumanDesignForm isMobile={isMobile} isExperimental={false} />
          <Tags
            wrapperClassName={s.tags}
            tags={tags}
            isMobile={isMobile}
          />
          <Footer
            className={cn(
              s.footer,
              s.footerDesktop,
            )}
            likesClassName={s.likes}
            title={title}
            link={link}
            likes={likes}
            isMobile={isMobile}
            key={likes ? likes.codename : ''}
          />
        </div>
      )}
    />
  );
}

export default HumanDesignProfilesPage;
